import React from "react";
import Configurations from "../../editable-stuff/configurations.json";

const parentTalk = Configurations.parentTalk;

const ParentTalk = () => {
  return (
    <div id="parenttalk" className="jumbotron jumbotron-fluid m-0">
      <div className="container container-fluid p-5">
        <div className="row align-center">
          <h1 className="display-4 mb-5 text-center">Family Talk</h1>
        </div>
        <div className="row">
            {
              parentTalk.map(talk => 
                <blockquote key={talk.name} className="q-card q-card-color-3">
                  <div className="content">{talk.text}</div>
                  <div className='author'>{talk.name}</div>
                </blockquote>
              )
            }
        </div>
      </div>
    </div>
  );
};

export default ParentTalk;