import React from "react";

const SixToTwelve = ({ match }) => {

  return (
    <div>
        <span>
            Around this age, some of your child’s teeth have fallen or they will begin to fall. This is the age group where all of the milk teeth shed out of your child’s mouth and the permanent teeth take their place. It is very Important to preserve this set of teeth, It is the last set of teeth that will grow in the mouth. 
            <br />
            <br />
            By this age a <b>dental home</b> must be set up, making a visit to the dentist a non-stressful event for your child. If not, it is still a good idea to establish this while we can. If you are wondering what a *dental home* is. The dental home is the ongoing relationship between the dentist and the patient, inclusive of all aspects of oral health care delivered in a comprehensive, continuously accessible, coordinated, and family-centred way. Till the age of 8, the children can relatively easily settle into and accept a new environment. The child can watch his/her sibling interacting with the dentist to help ease in. 
            <br />
            <br />
            <b>Let's move into a few common dental problems you might face in your journey.</b>
        </span>

        <h2 className="text-center mt-5">Delayed eruption</h2>
        <span>
            There are two sets of teeth that erupt in the mouth. One being the primary or deciduous or milk teeth and these shed or fall off to give rise to the permanent teeth or secondary teeth, which you can expect to see in their mouth now. The permanent teeth should last all of us for a large part of our adult lives. 
            <br />
            <br />
            Children with CDLS usually show a delay in the eruption of their teeth. In the age range of six to twelve you can expect their permanent teeth to erupt. The ideal number of teeth that need to be present on the upper and lower arch are  14 + 14 - 28 teeth, With 14 in each jaw (sometimes 32 teeth, with 16 in each jaw ).  
            <br />
            <br />
            The teeth can erupt over a long period of time. In this age range, you can expect 5 to 10 teeth in each jaw to erupt. It is very common for not all teeth to erupt. This may lead to spacing between teeth, but do not cause any harm. Spacing between teeth is very common for children with CDLS. 
            <br />
            <br />
            <h5>How can you help?</h5>
            At this age it is best to let the child grow and slowly assess what teeth are missing. The Teeth that you need to absolutely see in their mouth are their incisors (front teeth) and their first molars (main tooth responsible for chewing). 
            <br />
            <br />
            You can continue on your scheduled appointments with your dentist and they will guide you on how to proceed. Having missing teeth is very common for children with CDLS. 
        </span>
        

        <h2 className="text-center mt-5">Dental Erosion</h2>
        <span>
            This appears in the mouth initially as a whitish appearance of the tooth. The tooth appears extra white as the minerals of the enamel are being eroded away. This will eventually lead to a decay/ cavity which will eventually lead to an infection of the pulp. The chances of this occurring in permanent teeth are quite high as they are exposed to the stomach acid for a long period of time.
            <br />
            <br />
            <h5>What is dental erosion?</h5>
            <br />
            <br />
            Dental erosion is a chemical process characterized by the acid dissolution of dental hard tissue (enamel) not involving acids of bacterial origin. The main reason why individuals with CDLS can face dental erosion is due to Gastroesophageal reflux disease, which is a very common feature of the syndrome. 
            <br />
            <br />
            Gastroesophageal reflux disease (GERD) is a common condition in which the stomach contents (including stomach acid) move up into the esophagus and the mouth. Reflux becomes a disease when it causes frequent or severe symptoms or injury. Reflux may damage the esophagus, pharynx or respiratory tract and erodes the enamel of the tooth.
            <h5 className="mt-2">How can you help?</h5>
            GERD is very common in children with Cornelia de Lange syndrome. To treat this, you will need to consult a *gastroenterologist*. If it is manageable, the child will be relieved of future pain. To maintain dental health during this period.
            <br />
            <br />
            In consultation with a dentist Using a re-mineralizing agent, such as sodium fluoride solution in the form of a fluoride mouth rinse, tablet, or lozenge, immediately before brushing teeth reduces dental erosion. Also, apply fluoride gels or varnishes to the teeth.
            <br />
            <br />
            In children, mouthwashes must be used with caution, If their ability to swallow is not fully developed, they may have a hard time spitting. If that is the case, mouthwashes need to be avoided.

        </span>

        <h2 className="text-center mt-5">Cavities</h2>
        <span>
            Cavities are permanently damaged areas in the hard surface of your teeth that develop into tiny openings or holes. Cavities, also called tooth decay or caries, are caused by a combination of factors, including bacteria in your mouth, frequent snacking, sipping sugary drinks and not cleaning your teeth well. This is a very common and frequent issue the child will face through his/her development. 
        </span>
        <div className="row">
            <div className="col-8">
                <span>
                    <h5 className="mt-2">How can you help?</h5>

                    Prevention, Prevention, Prevention! The only way to help is to make sure that a cavity does not occur. That is easier said than done. There are a few ways that you can prevent caries.

                    <ul className="ml-5">
                        <li>Brushing with the right technique twice a day. Once early morning, before breakfast and once after dinner, late evening.</li>
                        <li>Using a high fluoride toothpaste. This needs to be used only if advised by your dentist and the child has the ability to spit. Fluoride is toxic if consumed (swallowed) in large quantities.</li>
                        <li>Using floss to floss between teeth.</li>
                        <li>Rinsing the mouth or drinking water after every meal.</li>
                        <li>Limiting the frequency of consumption of sugar. Greater the frequency of consumption of sugar, greater the chances of cavities.</li>
                        <li>Fluoride varnish can be applied by your dentist.</li>
                        <li>Dental Sealants prevent cavities. This is applied within the first year of eruption of the <b>molars</b></li>
                        <li>Using an electric toothbrush to brush their teeth.</li>
                        <li>Once the permanent molars erupt, it is necessary for them to have <b>sealants</b> placed in them. This prevents easy formation of Cavities. </li>
                    </ul>

                    The chances are that your child does not have the sensory ability to brush on their own at this age. They must be eased into brushing their own teeth only once they are able.
                </span>
            </div>
            <div className="col-4 d-flex flex-wrap align-items-center">
                <img className="h-50" src={`${process.env.PUBLIC_URL}\\cavity_mixed.JPG`} alt="Cavity" />
            </div>
        </div>

        <h2 className="text-center mt-5">Speech impairment</h2>
        <span>
            The most severely affected area of development is speech and this is often affected by hearing impairments. Many children with the syndrome will never learn to speak but with the right help and encouragement they should acquire a number of words. 

            <h5 className="mt-2">How can you help?</h5>
            This is an ideal age range to aid the child with speech if not attained already. 
            Teaching non-verbal communication skills to support verbal language development such as sign language or picture exchange systems is also important. 
            Children who have a mild form of the syndrome can learn to talk fluently, even though they may be five or six years old before they start talking.
            Consulting a <b>speech therapist</b> can help the child to pick up communication cues. This will help the child greatly. 


        </span>

        <h2 className="text-center mt-5">Tips to help with dental care</h2>
        <span>
            Caregivers should encourage regular brushing or flossing despite minor gum bleeds. However, excessive bleeding should be checked for other underlying conditions, such as gum disease.

            <h5 className="mt-2">Hygiene</h5>
            <ul className="ml-5">
                <li>Daily use of <b>chlorhexidine</b> (in a rinse, spray bottle or toothbrush), an antimicrobial agent if necessary. This prevents gum disease.</li>
                <li>Use fluoride toothpaste or fluoride rinse</li>
            </ul>

            <h5 className="mt-2">Change up brushing habits</h5>
            <ul className="ml-5">
                <li>Special <b>manual</b> (non-electric) toothbrush, such as the three-sided toothbrush, can help reduce gum inflammation compared to other brushes</li>
                <li>Use a flosser that comes with a handle instead of traditional floss</li>
                <li>If an electric toothbrush provides too much vibration, use a manual toothbrush</li>
            </ul>

            <div className="row align-center">
            <div className="col-4">
                <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}\\toothbrush.png`} alt="Tooth Brush" />
                <p className="legend text-center">Tooth Brush</p>
            </div>
            <div className="col-5">
                <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}\\floss.jpg`} alt="Floss" />
                <p className="legend text-center">Floss</p>
            </div>
        </div>

            <h5>Diet</h5>
            <ul className="ml-5">
                <li>Avoid excessive sweets, refined carbohydrates and medicines that contain sugar</li>
                <li>Encourage your loved one with CDLS to drink water more often. Limit sweetened beverages, such as soda or juice</li>
            </ul>
        </span>
    </div>
  );
};

export default SixToTwelve;