import React from "react";

const OneToSix = ({ match }) => {

  return (
    <div>
        <p>
            Has your cute baby sprouted his/her small front teeth? Adorable! Probably the best time to get some portraits with them clicked! Let’s work hand in hand to keep that smile healthy and lively on their face. A characteristic feature of children with CDLS is delayed eruption. Their teeth appear or erupt a little later than what is expected. Another feature that we can expect is Micrognathia- a small mouth. Their jaws, teeth and oral soft tissues can be altered due to poor growth and development. 
        </p>

        <p>
            You as a parent are probably looking for the best ways to care for your child. Taking care of the child to the best of your ability must be done in relation to a relevant medical professional. An aspect that must be taken care of through the growth of your child is his/her dental health. 
        </p>

        <p>
            Early consultation with a paediatric dental specialist is important. Paediatric dentists are trained in all aspects of assessment and treatment associated with children who possess special healthcare needs. For a healthy diet with the ability to swallow and management of delayed eruption of teeth, demand an early establishment of a “dental home.”
        </p>

        <p>
            You must be wondering what a <b>dental home</b> is. The dental home is the ongoing relationship between the dentist and the patient, inclusive of all aspects of oral health care delivered in a comprehensive, continuously accessible, coordinated, and family-centred way. This builds a form of trust that your child might find hard to establish once they mature.
        </p>

        <p>
            This cannot be emphasised enough. A child with CDLS is awry but accepting of new environments. One such environment that a child must be accepting of is that of their dentist. Only then will they be willing to undergo bi-yearly check-ups or whatever frequency is recommended by their dentist. Till the age of 8, the children can relatively easily settle into and accept a new environment. The child can watch his/her sibling interacting with the dentist to help ease in. 
        </p>

        <p>
            <b>Let’s move into a few common dental problems you might face in your journey.</b>
        </p>

        <h2 class="text-center">Delayed eruption</h2>
        <div className="row">
            <div className="col-8 center-para d-flex flex-wrap align-items-center">
                <span className="mt-5">
                    There are two sets of teeth that erupt in the mouth. One being the primary or deciduous or milk teeth and these shed or fall off to give rise to the permanent teeth or secondary teeth. The permanent teeth should last all of us for a large part of our adult lives. 
                    <br />
                    <br />
                    Children with CDLS usually show a delay in the eruption of their teeth.  In the age range of one to six you can expect their milk teeth to erupt. The ideal number of teeth that need to be present on the upper and lower arch are 10 + 10 - 20 teeth, With 10 in each jaw. It is very common for not all teeth to erupt. This may lead to spacing between teeth, but do not cause any harm. Spacing between teeth is very common. 
                </span>
            </div>
            <div className="col-4 d-flex flex-wrap align-items-center">
                <img src={`${process.env.PUBLIC_URL}\\delayed_eruption.jpg`} alt="Delayed eruption" />
            </div>
        </div>


        <h2 className="text-center mt-5">Micrognathia</h2>
        <span>
            Individuals with CDLS have a common characteristic of having a small mouth. Micrognathia is when the lower jaw (mandible) is smaller than normal. This can lead to a smaller than usual opening of the mouth. 
            This poses as a challenge to eat and maintain oral health.  
        </span>
        <br />
        <br />
        <h5>How can you help? </h5>
        <span>
            In case the opening is extremely limited, the chances of this occurring is quite rare, you should discuss this immediately with your pedodontist, who will put in a treatment plan to reduce the limited opening. 
            <br />
            <br />
            Once the molars erupt ( the back teeth in the jaws), you should visit your dentist and inquire of sealants are required. Dental sealants are thin coatings that when painted on the chewing surfaces of the back teeth (molars) can prevent cavities (tooth decay) for many years. 
            Another Step you can take to prevent cavities are fluoride varnishes. Which indeed reduce the occurrence of caries.
        </span>

        <h2 className="text-center mt-5">Cavities</h2>
        <span>
            Cavities are permanently damaged areas in the hard surface of your teeth that develop into tiny openings or holes. Cavities, also called tooth decay or caries, are caused by a combination of factors, including bacteria in your mouth, frequent snacking, sipping sugary drinks and not cleaning your teeth well. This is a very common and frequent issue the child will face through his/her development.
        </span>
        <div className="row">
            <div className="col-8 mt-10">
                <span>
                    <h5>How can you help?</h5>

                    Prevention, Prevention, Prevention! The only way to help is to make sure that a cavity does not occur. That is easier said than done. There are a few ways that you can prevent caries. 

                    <ul className="ml-5">
                        <li>Brushing with the right technique twice a day. Once early morning, before breakfast and once after dinner, late evening. </li>
                        <li>Using a high fluoride toothpaste. This needs to be used only if advised by your dentist and the child has the ability to spit. Fluoride is toxic if consumed (swallowed) in large quantities.</li>
                        <li>Using floss to floss between teeth.</li>
                        <li>Rinsing the mouth or drinking water after every meal.</li>
                        <li>Limiting the frequency of consumption of sugar. Greater the frequency of consumption of sugar, greater the chances of cavities.</li>
                        <li>Fluoride varnish can be applied by your dentist.</li>
                        <li>Dental Sealants prevent cavities. This is applied within the first year of eruption of the <b>molars</b></li>
                        <li>Using an electric toothbrush to brush their teeth.</li>
                    </ul>

                    The chances are that your child does not have the sensory ability to brush on their own at this age. They must be eased into brushing their own teeth only once they are able.
                </span>
            </div>
            <div className="col-4 d-flex flex-wrap align-items-center">
                <img src={`${process.env.PUBLIC_URL}\\cavity.jpg`} alt="Cavity" />
            </div>
        </div>


        <h2 className="text-center mt-5">Speech impairment</h2>
        <span>
            The most severely affected area of development is speech and this is often affected by hearing impairments. Many children with the syndrome will never learn to speak but with the right help and encouragement they should acquire a number of words. 

            <h5 className="mt-2">How can you help?</h5>
            Teaching non-verbal communication skills to support verbal language development such as sign language or picture exchange systems is also important. 
            Children who have a mild form of the syndrome can learn to talk fluently, even though they may be five or six years old before they start talking.
            Consulting a <b>speech therapist</b> can help the child to pick up communication cues. This will help the child greatly. 

        </span>

        <h2 className="text-center mt-5">Tips to help with dental care</h2>
        <span>
            Caregivers should encourage regular brushing or flossing despite minor gum bleeds. However, excessive bleeding should be checked for other underlying conditions, such as gum disease.

            <h5 className="mt-2">Hygiene</h5>
            <ul className="ml-5">
                <li>Daily use of <b>chlorhexidine</b> (in a rinse, spray bottle or toothbrush), an antimicrobial agent if necessary. This prevents gum disease.</li>
                <li>Use fluoride toothpaste or fluoride rinse</li>
            </ul>

            <h5 className="mt-2">Change up brushing habits</h5>
            <ul className="ml-5">
                <li>Special <b>manual</b> (non-electric) toothbrush, such as the three-sided toothbrush, can help reduce gum inflammation compared to other brushes</li>
                <li>Use a flosser that comes with a handle instead of traditional floss</li>
                <li>If an electric toothbrush provides too much vibration, use a manual toothbrush</li>
            </ul>

            <div className="row align-center">
            <div className="col-4">
                <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}\\toothbrush.png`} alt="Tooth Brush" />
                <p className="legend text-center">Tooth Brush</p>
            </div>
            <div className="col-5">
                <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}\\floss.jpg`} alt="Floss" />
                <p className="legend text-center">Floss</p>
            </div>
        </div>

            <h5>Diet</h5>
            <ul className="ml-5">
                <li>Avoid excessive sweets, refined carbohydrates and medicines that contain sugar</li>
                <li>Encourage your loved one with CDLS to drink water more often. Limit sweetened beverages, such as soda or juice</li>
            </ul>
        </span>
    </div>
  );
};

export default OneToSix;