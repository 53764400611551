import React from "react";
import Configurations from "../../editable-stuff/configurations.json";

const resources = Configurations.resources;

const Resources = () => {
  return (
    <div id="resources" className="jumbotron jumbotron-fluid bg-transparent m-0">
      <div className="container container-fluid p-5">
        <div className="row align-center">
          <h1 className="display-4 mb-5 text-center">Resources</h1>
        </div>
        <div className="row">
            <div className="col-lg-6">
                <div className="list-group">
                    {
                        resources.websites.map(site => 
                            <a 
                            key={site.name}
                            href={site.href}
                            target="_blank"
                            rel="noreferrer"
                            className="list-group-item list-group-item-action"
                             >{site.name}</a>
                        )
                    }
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;