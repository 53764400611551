import React from "react";
import { useParams } from "react-router-dom";
import AtBirth from "../../editable-stuff/dentalhealth/AtBirth";
import OneToSix from "../../editable-stuff/dentalhealth/OneToSix";
import SixToTwelve from "../../editable-stuff/dentalhealth/SixToTwelve";
import ThirteenToAdult from "../../editable-stuff/dentalhealth/ThirteenToAdult";

const POSTS = {
    0: {
        title: 'At Birth',
        file: <AtBirth />
    },
    1: {
        title: 'One To Six Years of Age',
        file: <OneToSix />
    },
    2: {
        title: 'Six to Twelve years of Age',
        file: <SixToTwelve />
    },
    3: {
        title: 'Thirteen years to Adult',
        file: <ThirteenToAdult />
    }
}

const DentalHealth = () => {
  let { id } = useParams();
  const post = POSTS[id];

  return (
    <div className="container-lg mt-5">
      {post && (
        <div>
          <h1 className="display-2 text-center">{post.title}</h1>
          {/* <ReactMarkdown children={data} remarkPlugins={[remarkGfm]} /> */}
          { post.file }
        </div>
      )}
      {!post && <h1 className="display-1 text-center">404 - Page not found</h1>}
      <div className="text-center">
      <a href={`${process.env.PUBLIC_URL}/`} rel="noopener noreferrer">
        Go Back to Main Page
      </a>
      </div>
    </div>
  );
};

export default DentalHealth;