import React from "react";
import Configurations from "../../editable-stuff/configurations.json";

const aboutHeading = Configurations.aboutHeading;
const aboutDescription = Configurations.aboutDescription;
const cdlsImg = Configurations.cdlsImg;

const AboutMe = () => {
  return (
    <div id="aboutme" className="jumbotron jumbotron-fluid m-0">
      <div className="container container-fluid p-5">
        <div className="row align-center">
          <h1 className="display-4 mb-5 text-center">{aboutHeading}</h1>
        </div>
        <div className="row">
          <div className="col-lg-5 d-none d-lg-block align-self-center fade-in-image">
            <img
              className="border border-secondary rounded-circle w-100 h-25 mr-5"
              src={`${process.env.PUBLIC_URL}\\${cdlsImg}`}
                alt="CDLS Kid"
            />
          </div>
          <div className="col-lg-7">
            <p className="lead text-left d-lg-block align-self-center w-75 h-100">{aboutDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;