import React from "react";
import Configurations from "../../editable-stuff/configurations.json";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {Tooltip as ReactTooltip} from "react-tooltip";

const experience = Configurations.experience;

const Experience = () => {
  const getCarousel = () => {
      return (
        <Carousel
          autoFocus="true"
          autoPlay="true"
          infiniteLoop="true"
          interval="2500"
          transitionTime="1000"
        >
          {
            experience.projects.map((project, index) =>
            <a 
              className={`card${index}`}
              key={project.name}
              href={`${process.env.PUBLIC_URL}/dentalhealth/${index}`}
              data-tooltip-place="top"
              data-tooltip-id={`toolTip${index}`}
              data-tooltip-content="Click me to view more information"
            >
              <div>
                  <img src={`${process.env.PUBLIC_URL}\\${project.src}`} alt={project.name} />
                  {/* <p className="legend">{project.name}</p> */}
              </div>
            </a>)
          }
        </Carousel>
      );
  };

  return (
    <div id="experience" className="jumbotron jumbotron-fluid bg-transparent m-0">
      <div className="container container-fluid p-5">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="display-4 mb-5 text-center">{experience.heading}</h1>
            <h5 className="text-center">Click the moving card to know more</h5>
            {getCarousel()}
            {experience.projects.map((project, index) => 
              <ReactTooltip key={index} id={`toolTip${index}`} anchorSelect={`.card${index}`} place="top" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;