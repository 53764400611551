import React from "react";

const AtBirth = ({ match }) => {

  return (
    <div>
        <p>
        Some features we can appreciate in children with CDLS is thick and curly hair eyebrows meeting at the midline, and long and curved eyelashes. We can also see anteverted nostrils, upturned and a small nose, thin lips with the downward turned angles of the mouth, (micrognathia) small mouth, short neck, fairly small feet and hands. Fusion of fingers (Syndactyly) is also a classic feature. 
        It is highly likely that the child could be born underweight. 
        </p>

        <p>
        Taking care of the child to the best of your ability must be done in relation with a relevant medical professional. An aspect that must be take care of through the growth of your child is his/her dental health. 
An early consultation with a paediatric dental specialist is important. Paediatric dentists are trained in all aspects of assessment and treatment associated with children who possess special health care needs. For a healthy diet with ability to swallow and management of delayed eruption of teeth, demand an early establishment of a “dental home.” 
        </p>

        <p>
        You must be wondering what a dental home is?  The dental home is the ongoing relationship between the dentist and the patient, inclusive of all aspects of oral health care delivered in a comprehensive, continuously accessible, coordinated, and family-cantered way. This builds a form of trust that your child might find hard to establish once they matures.
        </p>

        <p>
        <strong>Let’s move into a few common dental problems you might face in the first year of your journey.</strong>
        </p>

        <br />
        <br />
        <h2 className="text-center">Cleft Palate</h2>

        <p>It is common to have <b>clefting of the palate</b>, which can affect infant feeding, speech development and proper jaw growth.</p>

        <div className="row">
            <div className="col-8 center-para">
                <span>
                    <h5>What is Cleft Palate?</h5> 
                    The roof of the mouth (palate) is formed between the sixth and ninth weeks of pregnancy. A cleft palate happens if the tissue that makes up the roof of the mouth does not join together completely during pregnancy. For some babies, both the front and back parts of the palate are open.</span>
            </div>
            <div className="col-4">
                <img src={`${process.env.PUBLIC_URL}\\cleft_palate.jpeg`} alt="Cleft Palate" />
                <p className="legend text-center">Cleft Palate</p>
            </div>
        </div>

        <h5>How can you help?</h5>
        <p>Cleft palate repair should occur between 6 and 12 months of age. This can be altered depending on the medical condition of the baby. It is generally repaired through surgery. A consultation with an <b>Oral and Maxillofacial Surgeon</b> and a cleft team must be taken in order to finalise and plan the treatment specialised for your child. </p>

        <br />
        <br />
        <h2 className="text-center">Tongue-Tie</h2>
        <p><b>Ankyglossia</b>, or tongue-tie, is found in many children and can greatly affect speech development and proper eating. This condition can also be a factor in excessive drooling, making it difficult to swallow effectively.</p>

        <div className="row">
            <div className="col-4">
                <span>
                    <br />
                    <br />
                    <br />
                    <h5>What is ankyglossia?</h5> 
                    Tongue-tie (ankyloglossia) is a condition in which an unusually short, thick or tight band of tissue (lingual frenulum) is attached to the bottom of the tongue's tip to the floor of the mouth.
                    
                    <br />
                    <br />
                    <br />
                    <h5>How can you help?</h5>
                    If necessary, tongue-tie can be treated with a surgical cut to release the frenulum (frenotomy).
If your baby has tongue-tie and is feeding okay, you may choose to wait and see if his or her lingual frenulum stretches on its own.
                </span>
            </div>
            <div className="col-2"></div>
            <div className="col-lg-5 d-none d-lg-block align-self-center">
                <img 
                className="w-50 h-25 mr-5"
                src={`${process.env.PUBLIC_URL}\\tongue_tie.png`}
                 alt="Tongue Tie" />
                <p className="legend">Tongue Tie</p>
            </div>
        </div>

        <br />
        <br />
        <h2 className="text-center">Epstein Pearls</h2>
        <p>Epstein pearls are like a form of acne but they occur in the mouth. They are completely harmless and will eventually take care of themselves, so don't worry about them affecting your baby's health. 
How can you help?
These pearl like bumps tend to resolve by themselves.</p>
        <div className="text-center">
            <img className="w-75" src={`${process.env.PUBLIC_URL}\\gum_pearls.JPG`} alt="Epstein Pearls" />
            <p className="legend text-center">Epstein Pearls</p>
        </div>

        <br />
        <br />
        <h2 className="text-center">General Dental health practices you can maintain </h2>
        <ul>
            <li>Even before your baby's teeth come out you need to clean their mouth twice a day.</li>
            <li>Wipe gums twice a day with a soft, clean cloth in the morning after the first feeding and right before bed to wipe away bacteria and sugars that can cause cavities.</li>
            <li> When teeth come in, start brushing twice a day with a soft, small‑bristled toothbrush and plain water.</li>
            <li>In general, First tooth appears around 6 months of age.  Delayed eruption of teeth can be expected in babies with CDLS.</li>
        </ul>
        <br />
    </div>
  );
};

export default AtBirth;