import React from "react";

const ThirteenToAdult = ({ match }) => {

  return (
    <div>
        <span>
            Around this age, most of your child’s permanent teeth would have erupted. Incase some have not, it is not a reason for concern. It is very common for individuals with CDLS to not have all their permanent teeth present. On consultation with a dentist, he can decide on what needs to be done if absolutely necessary. 
            <br />
            <br />
            Around this age is when your child will hit his/her growth spurt. This growth spurt can be utilised by dentists to better jaw disparity if any. Apart from the existing dental problems, at this age, It is very important to build healthy dental habits and prevent the occurrence of cavities. 
        </span>
 
        <h2 className="text-center mt-5">Dental erosion</h2>
        <span>
            This appears in the mouth initially as a whitish appearance of the tooth. The tooth appears extra white as the minerals of the enamel are being eroded away. This will eventually lead to a decay/ cavity which will eventually lead to an infection of the pulp. The chances of this occurring in permanent teeth are quite high as they are exposed to the stomach acid for a long period of time.
            <br />
            <br />
            <h5>What is dental erosion?</h5>
            <br />
            <b>Dental erosion</b> is a chemical process characterized by the acid dissolution of <b>dental</b>hard tissue (enamel) not involving acids of bacterial origin. The main reason why individuals with CDLS can face dental erosion is due to Gastroesophageal reflux disease, which is a very common feature of the syndrome. 
            <br />
            <br />
            Gastroesophageal reflux disease (GERD) is a common condition in which the stomach contents (including stomach acid) move up into the esophagus and the mouth. Reflux becomes a disease when it causes frequent or severe symptoms or injury. Reflux may damage the esophagus, pharynx or respiratory tract and erodes the enamel of the tooth.
        </span>
        <span>
            <h5 className="mt-4">How can you help?</h5>
            <br />
            GERD is very common in children with Cornelia de Lange syndrome. To treat this, you will need to consult a *gastroenterologist*. If it is manageable, the child will be relieved of future pain. To maintain dental health during this period.
            <br />
            <br />
            In consultation with a dentist Using a re-mineralizing agent, such as sodium fluoride solution in the form of a fluoride mouth rinse, tablet, or lozenge, immediately before brushing teeth reduces dental erosion. Also, apply fluoride gels or varnishes to the teeth.
            <br />
            <br />
            In Individuals with CDLS, mouthwashes must be used with caution, If their ability to swallow is not fully developed, they may have a hard time spitting. If that is the case, mouthwashes need to be avoided.

        </span>

        <h2 className="text-center mt-5">Cavities</h2>
        <span>
            Cavities are permanently damaged areas in the hard surface of your teeth that develop into tiny openings or holes. Cavities, also called tooth decay or caries, are caused by a combination of factors, including bacteria in your mouth, frequent snacking, sipping sugary drinks and not cleaning your teeth well. This is a very common and frequent issue the child will face through his/her development. 
        </span>
        <div className="row">
            <div className="col-8 mt-10">
                <span>
                    <h5 className="mt-2">How can you help?</h5>

                    Prevention, Prevention, Prevention! The only way to help is to make sure that a cavity does not occur. That is easier said than done. There are a few ways that you can prevent caries.

                    <ul className="ml-5">
                        <li>Brushing with the right technique twice a day. Once early morning, before breakfast and once after dinner, late evening.</li>
                        <li>Using a high fluoride toothpaste. This needs to be used only if advised by your dentist and the child has the ability to spit. Fluoride is toxic if consumed (swallowed) in large quantities.</li>
                        <li>Using floss to floss between teeth.</li>
                        <li>Rinsing the mouth or drinking water after every meal.</li>
                        <li>Limiting the frequency of consumption of sugar. Greater the frequency of consumption of sugar, greater the chances of cavities.</li>
                        <li>Fluoride varnish can be applied by your dentist.</li>
                        <li>Dental Sealants prevent cavities. This is applied within the first year of eruption of the <b>molars</b></li>
                        <li>Using an electric toothbrush to brush their teeth.</li>
                        <li>Once the permanent molars erupt, it is necessary for them to have <b>sealants</b> placed in them. This prevents easy formation of Cavities. </li>
                        <li>Please consult with your dentist regarding dental sealants in all the back teeth.</li>
                    </ul>
                </span>
            </div>
            <div className="col-4 d-flex flex-wrap align-items-center">
                <img className="w-100 h-50" src={`${process.env.PUBLIC_URL}\\cavity_adult.JPG`} alt="Cavity" />
            </div>
        </div>
        <span>
            <h5 className="mt-2">Incase your loved one requires a root canal treatment-</h5>
            At this age, it is possible that your loved one has had an infection and is in requirement of a RCT.
            <ul className="ml-5">
                <li>Incase your child can handle the dental clinic and is comfortable in a dental sitting, he/she can undergo the procedure in a chair.</li>
                <li>Incase he/she is not comfortable, you can look for dentists who offer sedation. They will require a few details and scans regarding the child’s health ( not limited to but including heart, lungs and liver). Here they can undergo the procedure under sedation.</li>
                <li>If the above is not possible, it is best to get the best possible treatment done under general anaesthesia. Incase this were to arise, please do a complete dental rehabilitation to avoid future complications.</li>
            </ul>
        </span>

        <h2 className="text-center mt-5">Speech impairment</h2>
        <span>
            The most severely affected area of development is speech and this is often affected by hearing impairments. Many children with the syndrome will never learn to speak but with the right help and encouragement they should acquire a number of words. 

            <h5 className="mt-2">How can you help?</h5>
            <ul className="ml-5">
 
                <li>Teaching non-verbal communication skills to support verbal language development such as sign language or picture exchange systems is also important.</li>
                <li>Children who have a mild form of the syndrome can learn to talk fluently, even though they may be five or six years old before they start talking.</li>
                <li>Consulting a <b>speech therapist</b> can help the child to pick up communication cues. This will help the child greatly.</li>
            </ul>
            <h5 className="mt-2">Orthodontic Consultation </h5>
            <br/>
            Around the age of 13-15 is a great age to get an orthodontic consultation. The orthodontist will suggest what will be necessary to bring in harmonious occlusion, which will help the child chew better. This is advisable in children with mild CDLSs. Children with complications will not be able to tolerate orthodontic intervention well. 
        </span>

        <h2 className="text-center mt-5">Bruxism</h2>
        <span>
            <h5 className="mt-2">What are the signs of bruxism?</h5>
            <br />
            <b>Bruxism</b> is very common in people with CDLS. The main signs of bruxism are clenching and grinding of teeth. People can have bruxism while they are awake, asleep or both. It can be caused by behavioral (as part of harmless repetitive habits), psychosocial, anatomical or sleep position concerns. Bruxism could also be related to stress, anxiety and sleep-related disorders, such as sleep apnea. Sometimes, bruxism can lead to further changes in tooth shape and cause jaw pain.
            <br />
            <h5 className="mt-2">What are some tips to manage bruxism?</h5>
            There are many ways to manage bruxism. It is important to talk with the care team to figure out what works best for your loved one. Bruxism can stop on its own over time. Patient or caregivers should check for new pain symptoms or tooth anomalies (unusual changes in tooth shape, size or symptoms). Let your dentist know. If symptoms do not go away, your care team will do sleep studies to check whether your loved one may have sleep apnea or sleep-related disorders.
            <br />
            <br />
            Bruxism can be managed at home in the following ways:
            <ul className="ml-5">
                <li>Reduce stressors and promote a relaxing night-time routine.</li>
                <li>Head, neck, or jaw massage to relieve muscle pain.</li>
                <li>Avoid hard to chew foods before bed.</li>
                <li>Use additional or structured pillow for appropriate head and neck support.</li>
                <li>Ask the dentist or sleep doctor about proper mouth and jaw positioning during sleep.</li>
                <li>Practice mouth exercises:
                    <ul className="ml-5">
                        <li>Close lips gently while also preventing top and bottom teeth from touching</li>
                        <li>Tongue is pressed against roof of the mouth without touching teeth</li>
                        <li>Hold position from step 1 and 2 for as long as you can</li>
                    </ul>
                </li>
            </ul>
            <h5 className="mt-2">Are there dental devices that the dentist or orthodontist can recommend to help with bruxism?</h5>
            Yes, there are many dental devices that can help manage bruxism, including:
            <ul className="ml-5">
                <li>Mouth guards (not usually recommended for children because of the risk of choking)</li>
                <li>Mandibular advancement devices (MAD, or a device used to treat sleep apnea that opens the airway by gently moving the lower jaw forward)</li>
            </ul>
            Most importantly, visit the dentist at least every 6 months.
        </span>

        <h2 className="text-center mt-5">Tips to help with dental care</h2>
        <span>
            Caregivers should encourage regular brushing or flossing despite minor gum bleeds. However, excessive bleeding should be checked for other underlying conditions, such as gum disease.

            <h5 className="mt-2">Hygiene</h5>
            <ul className="ml-5">
                <li>Daily use of <b>chlorhexidine</b> (in a rinse, spray bottle or toothbrush), an antimicrobial agent if necessary. This prevents gum disease.</li>
                <li>Use fluoride toothpaste or fluoride rinse</li>
            </ul>

            <h5 className="mt-2">Change up brushing habits</h5>
            <ul className="ml-5">
                <li>Special <b>manual</b> (non-electric) toothbrush, such as the three-sided toothbrush, can help reduce gum inflammation compared to other brushes</li>
                <li>Use a flosser that comes with a handle instead of traditional floss</li>
                <li>If an electric toothbrush provides too much vibration, use a manual toothbrush</li>
            </ul>

            <div className="row align-center">
            <div className="col-4">
                <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}\\toothbrush.png`} alt="Tooth Brush" />
                <p className="legend text-center">Tooth Brush</p>
            </div>
            <div className="col-5">
                <img className="w-100 h-100" src={`${process.env.PUBLIC_URL}\\floss.jpg`} alt="Floss" />
                <p className="legend text-center">Floss</p>
            </div>
        </div>

            <h5>Diet</h5>
            <ul className="ml-5">
                <li>Avoid excessive sweets, refined carbohydrates and medicines that contain sugar</li>
                <li>Encourage your loved one with CDLS to drink water more often. Limit sweetened beverages, such as soda or juice</li>
            </ul>
        </span>
    </div>
  );
};

export default ThirteenToAdult;