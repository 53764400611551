import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import Configurations from "./editable-stuff/configurations.json";
import AboutMe from "./components/home/AboutMe";
import Resources from "./components/home/Resources";
import Experience from "./components/home/Experience";
import ContactMe from "./components/home/ContactMe";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { Blog } from "./components/blog/Blog";
import BlogPost from "./components/blog/BlogPost";
import ParentTalk from "./components/home/ParentTalk";
import DentalHealth from "./components/home/DentalHealth";

const showNavigationbar = Configurations.showNavigationbar;
const showBlog = Configurations.showBlog;

const Home = () => {
  return (
    <Fragment>
      <AboutMe />
      <Experience />
      <ParentTalk />
      <Resources />
      <ContactMe />
    </Fragment>
  );
};

const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
    {showNavigationbar && <Navbar />}
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dentalhealth/:id" exact element={<DentalHealth />} />
      {showBlog && <Route path="/blog" exact element={<Blog />} />}
      {showBlog && <Route path="/blog/:id" exact element={<BlogPost />} />}
    </Routes>
    <Footer />
  </BrowserRouter>
);

export default App;
